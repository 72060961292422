.operationControl .searchbox /deep/ .searchboxItem .el-checkbox-group .el-checkbox .el-checkbox__input .el-checkbox__inner {
  width: 14px;
  min-width: 14px;
  padding: 0 !important;
}
.CourseTable {
  width: 100%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;
}
.CourseTable .el-radio .el-radio__label {
  display: none;
}
.testTime {
  padding-top: 20px;
}
.testTime .itemLabel {
  display: inline-block;
  width: 130px;
  padding-bottom: 0.75rem;
  line-height: 2;
}
.testTime .testFomation {
  display: block;
  width: 300px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn button {
  width: 10rem;
}
