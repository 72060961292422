






































































































































































































































































































































































































































/*导入*/
.exportdialog {
    .el-dialog__title {
        font-size: 16px;
    }

    .export-box {
        width: 100%;
        margin-bottom: 20px;

        h1 {
            padding: 0 5px 10px;
            display: flex;
            align-items: flex-end;
            font-size: 40px;
            border-bottom: 1px dashed #ccc;

            span {
                margin-left: 20px;
                font-size: 14px;
            }
        }

        >div {
            padding-left: 30px;
        }

        .el-button.is-disabled,
        .el-button.is-disabled:focus,
        .el-button.is-disabled:hover {
            color: #fff;
            background: #c3c3c3;
            border: none;
        }
    }
}

.docsDialog {
    .el-form-item {
        margin-bottom: 0.5rem;
    }
}

.studentNum {
    margin: 20px 0 10px;

    span {
        margin: 0 10px;
    }
}

.exportdialog {
    .upload-workers {
        width: 80px;
        height: 35px;
        line-height: 35px;

        /deep/.el-upload {
            height: 35px !important;
            width: 80px;
            min-width: 80px;
            border: none !important;

            .el-button {
                padding: 5px 0;
                width: 100% !important;
            }
        }
    }
}
